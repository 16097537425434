<template>
    <v-app :class="appClass">
        <Header />
        <v-main>
            <Nuxt />
        </v-main>
        <VueLazyHydration when-visible>
            <Footer />
        </VueLazyHydration>
    </v-app>
</template>

<script>
import VueLazyHydration from 'vue-lazy-hydration';

export default {
    name: 'DefaultLayout',
    components: {
        VueLazyHydration,
        Header: () => import('~/components/common/header'),
        Footer: () => import('~/components/common/footer')
    },
    async fetch() {
        await this.$store.dispatch('fetchSharedData');
        await this.$store.dispatch('fetchProducts');
        await this.$store.dispatch('fetchAllPages');
    },
    computed: {
        appClass() {
            return { 'home-page': this.$route.name === 'index' };
        }
    },
    mounted() {
        this.$mixpanel?.mixpanel.track('Visit');
    }
};
</script>
