import { email, numeric, required, double } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

const minWords = {
  validate(value, { min }) {
    return value.replace(/\s+/g, ' ').split(' ').length > min;
  }
};

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
});
extend('email', {
  ...email,
  message: 'Email must be valid'
});

extend('numeric', {
  ...numeric,
  message: '{_field_} must only consist of numbers'
});

extend('min_words', {
  ...minWords,
  params: ['min'],
  message: '{_field_} must have at least {min} words'
});

extend('double', {
  ...double,
  params: ['decimals', 'separator'],
  message: '{_field_} allows {separator} separator with {decimals} digits'
});
