import { mdiThumbDownOutline, mdiThumbUpOutline, mdiMenu, mdiPlus, mdiEqual, mdiCalendar, mdiCurrencyUsd, mdiChevronDown } from '@mdi/js';

export default ({ app, store }, inject) => {
  inject('icons', {
    mdiThumbDownOutline,
    mdiThumbUpOutline,
    mdiMenu,
    mdiPlus,
    mdiEqual,
    mdiCalendar,
    mdiCurrencyUsd,
    mdiChevronDown
  });
};
