<template>
    <v-container class="error-page">
        <v-row no-gutters>
            <v-col cols="12" md="6" sm="8" offset-md="3" offset-sm="2">
                <nuxt-img
                    src="/public/img/error.png"
                    alt="0x4447, LLC - Error"
                    format="webp"
                    width="1000"
                    class="error-img"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        error: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            pageNotFound: '404 Not Found',
            otherError: 'An error occurred'
        };
    },
    head () {
        const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
        return {
            title
        };
    }
};
</script>

<style lang="scss" scoped>
.error-page {
    .error-img {
        margin: 0 auto;
        display: block;
        width: 100%;
        height: auto;
    }
}
</style>
