import compareVersions from 'compare-versions';

import { normalizeToProductName, normalizeToProductLink } from '~/utils/services/app-service';

export const state = () => ({
  sharedData: null,
  // products
  products: [],
  lastActiveProduct: null,
  // active product
  activeProduct: null,
  activeDocs: [],
  activeClients: [],
  // all other page contents
  pages: []
});

export const mutations = {
  setProducts(state, products) {
    state.products = products;
  },
  setLastActiveProduct(state, product) {
    state.lastActiveProduct = product;
  },
  setSharedData(state, sharedData) {
    state.sharedData = sharedData;
  },
  setActiveProduct(state, product) {
    state.activeProduct = product;
  },
  setActiveClients(state, clients) {
    state.activeClients = clients;
  },
  setActiveDocs(state, docs) {
    state.activeDocs = docs;
  },
  setPages(state, pages) {
    state.pages = pages;
  }
};

export const actions = {
  async fetchAllPages({ commit }) {
    const keys = ['slug', 'body', 'tagline', 'imgPath'];
    const pages = await this.$content('pages').only(keys).fetch().catch(() => []);
    commit('setPages', pages);
    return pages;
  },
  async fetchPageContent({ commit, state }, page) {
    const pageContent = await this.$content('pages', page).fetch().catch(() => null);

    if (!pageContent) {
      return null;
    }

    return pageContent;
  },
  async fetchSharedData({ commit, state }) {
    if (state.sharedData) {
      return state.sharedData;
    }

    const sharedData = await this.$content('_common').fetch();
    commit('setSharedData', sharedData);
    return sharedData;
  },
  async fetchProducts({ commit }) {
    const productList = await this.$content('products', { deep: true })
      .sortBy('slug', 'asc')
      .fetch();

    const products = productList.filter(p => p.dir.split('/').length === 3).map((product) => {
      const productName = normalizeToProductName(product.slug);

      return {
        alt: product.body.welcome.title,
        name: product.body.welcome.title,
        tag: product.body.welcome.tagline,
        tagline: product.body.welcome.tagline,
        description: product.body.welcome.description,
        descriptionSalt: product.body.welcome.description.replace(/(<([^>]+)>)/gi, ''),
        imgPath: `/public/img/products/${productName}/${productName}_logo.png`,
        link: normalizeToProductLink(product.slug)
      };
    });

    commit('setProducts', products);
    return products;
  },
  async fetchProductDetail({ commit, state, dispatch }, slug) {
    const productName = normalizeToProductName(slug);
    const products = await this.$content('products', productName).fetch().catch(() => []);

    if (products.length === 0) {
      return null;
    }

    if (!state.sharedData) {
      await dispatch('fetchSharedData');
    }

    const product = products[0];

    // shared data
    product.head.description = product.body.welcome.description.replace(/(<([^>]+)>)/gi, '');
    product.body.welcome.general = state.sharedData?.general?.why;
    product.body.welcome.solution = state.sharedData?.general?.solution;
    product.body.welcome.offer = state.sharedData?.offer;

    // const { welcome, ...otherSections } = product.body;

    // const offerSection = state.sharedData?.offer;
    // product.body = { welcome, offer: offerSection, ...otherSections };

    // product image
    product.image = `/public/img/products/${productName}/${productName}_logo.png`;

    commit('setActiveProduct', product);
    return product;
  },
  async fetchProductClients({ commit }, slug) {
    const productName = normalizeToProductName(slug);
    const clientFile = await this.$content(`products/${productName}/clients/db`).fetch()
      .catch(() => null);

    if (!clientFile) {
      return [];
    }

    const clients = clientFile.content.map(client => ({
      ...client,
      icon: `/public/img/products/${productName}/clients/${client.logo}`
    }));

    commit('setActiveClients', clients);
    return clients;
  },
  async fetchProductDocs({ commit }, slug) {
    const productName = normalizeToProductName(slug);
    const docs = await this.$content(`products/${productName}/documentation`).only(['slug', 'title']).fetch()
      .catch(() => []);
    commit('setActiveDocs', docs);
    return docs;
  },
  async fetchProductDoc({ commit }, { slug, version }) {
    const productName = normalizeToProductName(slug);
    const doc = await this.$content(`products/${productName}/documentation`, version).fetch()
      .catch(() => null);

    return doc || null;
  }
};

export const getters = {
  activeAwsURL: state => state.activeProduct ? state.activeProduct.body?.welcome?.url : null,
  activeLatestDocVersion: (state) => {
    const versions = state.activeDocs.map(d => d.slug);
    if (versions.length === 0) {
      return null;
    }

    const sortedVersions = versions.sort(compareVersions);
    return sortedVersions[sortedVersions.length - 1];
  },
  getPageTagline: state => (slug) => {
    const page = state.pages.find(p => p.slug === slug);
    return page?.body?.tagline || page?.tagline || '-';
  },
  getPageIcon: state => (slug) => {
    const page = state.pages.find(p => p.slug === slug);
    return page?.body?.imgPath || page?.imgPath || '';
  }
};
