<template>
    <v-app>
        <nuxt />
    </v-app>
</template>

<script>
export default {
    name: 'LandingLayout'
};
</script>
