import mixpanel from 'mixpanel-browser';

import { MIXPANEL_TOKEN } from '~/utils/constants/app-constants';

// eslint-disable-next-line import/no-mutable-exports
let MIXPANEL = null;

export default (_, inject) => {
  class MixpanelService {
    constructor() {
      this.mixpanel = mixpanel;
      this.init();
    }

    init() {
      this.mixpanel.init(MIXPANEL_TOKEN);
    }
  }
  if (window.location.hostname !== 'localhost') {
    MIXPANEL = new MixpanelService();
  }
  inject('mixpanel', MIXPANEL);
};

export {
  MIXPANEL
};
