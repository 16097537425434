const MIXPANEL_TOKEN = '4725e61f2f0bbd13e28d5fa937264ec5';

const BASE_URL = 'https://products.0x4447.com';

const AWS_LAMBDA_NAME = 'webpage_products_smtp';

export {
  BASE_URL,
  MIXPANEL_TOKEN,
  AWS_LAMBDA_NAME
};
