function normalizeToProductName(link) {
  return link === 'vpn-using-openvpn' ? 'vpn' : link;
}

function normalizeToProductLink(name) {
  return name === 'vpn' ? 'vpn-using-openvpn' : name;
}

function debounce(fn, wait = 400) {
  let timer;
  return (event) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(fn, wait, event);
  };
}

function throttle(fn, wait) {
  let throttleTimer;
  return () => {
    if (throttleTimer) {
      return;
    }
    throttleTimer = true;
    setTimeout(() => {
      fn();
      throttleTimer = false;
    }, wait);
  };
}

export {
  debounce,
  throttle,
  normalizeToProductName,
  normalizeToProductLink
};
